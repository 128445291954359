<template>
  <div>
    <v-card class="pa-4">
      <v-row>
        <v-col cols="12">
          <h1>POS Sales Product Report</h1>
        </v-col>
      </v-row>
      <v-row class="report-wrap">
        <v-col
          md="6"
          cols="12"
          class="d-flex align-end">
          <v-select
            v-model="query.brand"
            label="เลือก Brand"
            :items="allBrand"
            outlined
            dense
            class="mr-2"
            hide-details />
          <WarehouseSelect
            v-model="query.warehouseId"
            :items="warehouses"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
            label="Warehouses"
            outlined
            :disabled="fetching" />
        </v-col>
        <v-col
          md="6"
          cols="12"
          class="d-flex align-end">
          <date-picker
            v-model="dateSelected"
            small />
        </v-col>
        <v-col
          cols="12"
          class="d-flex justify-center align-end">
          <search-by-tags
            v-model="query"
            append-icon="mdi-magnify"
            tags-width="300px"
            :tags="tags"
            @on-search="fetchReportSummary()" />
          <v-btn
            color="success"
            class="ml-4"
            @click="exportCsv">
            csv
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mt-4">
      <v-data-table
        hide-default-footer
        :headers="headers"
        :items="items"
        item-key="skuId"
        :items-per-page="-1"
        class="elevation-1"
        :loading="fetching"
        dense>
        <template v-slot:[`item.image`]="{ item }">
          <div class="pa-2">
            <img
              :src="imgSrcCompute(item.images[0])"
              width="48"
              alt="">
          </div>
        </template>
        <template v-slot:[`item.code`]="{ item }">
          <span style="font-size: 12px;">{{ item.name }}</span> <br />
          <strong>{{ item.code }}</strong>
        </template>
        <template v-slot:[`item.options`]="{ item }">
          <span class="text-uppercase">
            {{ item.color }} - {{ item.size }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ExportToCsv } from 'export-to-csv'
import NewReportProvider from '@/resources/NewReportProvider'
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import SearchByTags from '@/components/SearchByTags.vue'
import WarehouseSelect from '@/components/WarehouseSelect.vue'
import DatesPicker from '../components/DatesPicker.vue'

const ProductAttributeService = new ProductAttributeProvider()
const NewReportService = new NewReportProvider()

export default {
  components: {
    DatePicker: DatesPicker,
    SearchByTags,
    WarehouseSelect
  },
  data () {
    return {
      fetching: false,
      items: [],
      tags: [],
      query: {
        search: '',
        tags: [],
        tagOperation: 'OR',
        brand: 'all',
        warehouseId: null
      },
      allBrand: [],
      headers: [
        { text: 'IMG', value: 'image', width: 120, sortable: false },
        { text: 'Code', value: 'code', align: 'left' },
        { text: 'Options', value: 'options', width: 120, align: 'center', sortable: false },
        { text: 'Amount', value: 'amount', width: 120, align: 'center' }
      ],
      dateSelected: [this.$dayjs().subtract(6, 'day').format(), this.$dayjs().format()]
    }
  },
  computed: {
    ...mapGetters({
      mapWarehouse: 'Store/mapWarehouse'
    }),
    warehouses () {
      return this.mapWarehouse.filter((warehouse) => warehouse.id !== 0)
    }
  },
  async mounted () {
    await this.getBrand()
    await this.getTags()
  },
  methods: {
    async getBrand () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('brands', {
          limit: 999
        })

        this.allBrand = [
        {
          text: 'All',
          value: 'all'
        },
          ...data.results.map((brand) => ({
            text: brand.name,
            value: brand.name
          }))
        ]
      } catch (error) {
        console.error('getAttribute: ', error)
      }
    },
    imgSrcCompute (src) {
      return `${src}?fm=jpg&w=400` || 'https://via.placeholder.com/150'
    },
    getSale (warehouse, sales) {
      const sale = sales.find((s) => s.warehouse.name === warehouse.name)
      return sale.amount
    },
    async getTags () {
      try {
        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setSnackbar({
          value: true,
          message: `[GET-TAGS-ERROR]: ${error.message}`,
          type: 'error'
        })
      }
    },
    async fetchReportSummary () {
      try {
        this.fetching = true

        if (!this.query.warehouseId) {
          this.$store.dispatch('Components/setSnackbar', {
            value: true,
            message: 'Please select warehouse first.',
            type: 'error'
          })
          return
        }

        const { data } = await NewReportService.getPosSalesProduct({
          ...this.query,
          startDate: this.$dayjs(this.dateSelected[0])
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format(),
          endDate: this.$dayjs(this.dateSelected[1]).add(1, 'day')
                      .hour(0)
                      .minute(0)
                      .second(0)
                      .format()
        })

        this.items = data
      } catch (err) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `[error on fetch report]: ${err.message}`,
          type: 'error'
        })
      } finally {
        this.fetching = false
      }
    },
    exportCsv () {
      const warehouse = this.warehouses.find((v) => v.id === this.query.warehouseId)
      const data = this.items.map((item) => ({
          code: item.code,
          name: item.name,
          // tags: item.tags.join(', '),
          // categories: item.categories.join(', '),
          color: item.color,
          size: item.size,
          amount: item.amount
        }))

      const options = {
          filename:
            // eslint-disable-next-line max-len
            `${warehouse.name}_product_sales_${this.$dayjs(this.dateSelected[0]).format('DD-MM-YYYY')}_${this.$dayjs(this.dateSelected[1]).format('DD-MM-YYYY')}`,
          showLabels: true,
          useKeysAsHeaders: true
        }

      const csvExporter = new ExportToCsv(options)
      csvExporter.generateCsv(data)
    }
  }
}
</script>

<style scoped>
.number-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  border: 1px solid #cfcfcf;
  position: relative;
}
.number-box .box-title {
  position: absolute;
  font-size: 14px;
  padding: 0 5px;
  top: -10px;
  left: 5px;
  background-color: #fff;
}
</style>
